import {
  AccountInfo,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { clientId, scopes, tenantId } from "./constants.prod";

export function getRoles(token: string) {
  const base64Url = token.split(".")[1];
  const tokenData = JSON.parse(atob(base64Url));

  return tokenData.roles;
}

export const getToken = async (instance: IPublicClientApplication) => {
  try {
    return await instance.acquireTokenSilent({
      ...request,
      account: instance.getAllAccounts()[0] as AccountInfo,
    });
  } catch (error) {
    instance.loginRedirect();
    throw error;
  }
};

export const pca = new PublicClientApplication({
  auth: {
    clientId: clientId,
    authority:
      `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    temporaryCacheLocation: "sessionStorage",
    secureCookies: false,
    claimsBasedCachingEnabled: false,
  },
  system: {
    // allowRedirectInIframe: true,
    // loggerOptions: {
    //   loggerCallback: (level: any, message: any, containsPii: any) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         return;
    //       default:
    //         return;
    //     }
    //   },
    // },
  },
});
export const request = {
  scopes: scopes,
};
