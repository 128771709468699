import axios from "axios";
import { Group } from "../models/Group";
import { url as apiurl } from "../auth/constants.prod";

export class Groups {
    private baseURL = apiurl + "/api/Group";

    public async getGroups(token: string): Promise<Group[]> {
        const response = await axios.get(`${this.baseURL}`, {headers: {Authorization: `Bearer ${token}`}});
        return response.data;
    }

    public async addDevicesToGroups(token: string, selectedGroupIds: string[], selectedDevice: string[]): Promise<void> {
        if (selectedGroupIds.length === 0) {
            console.log('No groups selected.');
        } else {
            for (const groupId of selectedGroupIds) {
                const url = `${this.baseURL}/${groupId}`;
                try {
                    const response = await axios.patch(url, selectedDevice, {headers: {Authorization: `Bearer ${token}`}});
                    console.log(`Selected devices have been added to ${groupId} group.`);
                } catch (error) {
                    console.error(`Failed to add selected devices to ${groupId} group.`);
                }
            }
        }
    }

    public async deleteDevicesFromGroups(token: string, selectedGroupIds: string[], selectedDevice: string[]): Promise<void> {
        if (selectedGroupIds.length === 0) {
            console.log('No groups selected.');
        } else {
            for (const groupId of selectedGroupIds) {
                const url = `${this.baseURL}/${groupId}`;
                try {
                    const response = await axios.delete(url, {
                        data: selectedDevice,
                        headers: {Authorization: `Bearer ${token}`}
                    });
                    console.log(`Selected devices have been deleted from ${groupId} group.`);
                } catch (error) {
                    console.error(`Failed to delete devices from ${groupId} group.`);
                }
            }
        }
    }
}