import { useState, useEffect } from 'react';
import { getToken } from "../auth/authConfig";
import { useMsal } from "@azure/msal-react";
import { Device } from "../models/Device";
import { Devices } from "../services/Devices";
import { Box, Button, Checkbox, CheckboxGroup, CloseButton, Divider, Flex, Grid, InlineNotification, Search, Spinner, Text, useToast } from "@lego/klik-ui";
import { DevicesLaptop } from '@lego/klik-ui/icons';

interface DeviceListProps {
    selectedDevice: (string | number)[];
    handleSelectedDevices: (newSelectedDevice: string[]) => void;
    resetDeviceSelection: () => void;
}
  
export const DeviceList: React.FC<DeviceListProps> = ({ selectedDevice, handleSelectedDevices, resetDeviceSelection }) => {

    const { instance } = useMsal();
    const toast = useToast();

    const onCloseComplete = () => {
        console.log('Device selection has been reset.');
    };

    const [devices, setDevices] = useState<Device[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [searchDevice, setSearchDevice] = useState<string>("");
    const [minHeight, setMinHeight] = useState('100vh');

    const filteredDevices = devices ? devices.filter(device => 
        device.displayName.toLowerCase().includes(searchDevice.toLowerCase())
    ) : [];

    useEffect(() => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            setMinHeight(`calc(100vh - ${navbar.offsetHeight}px)`);
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        getToken(instance).then(token => {
            const appService = new Devices();
            appService.getDevices(token.accessToken!, searchDevice).then((response: { data: Device[], odataLink: string }) => {
                setDevices(response.data);
                setIsLoading(false);
            });
        });
    }, [instance, searchDevice]);

    useEffect(() => {
        if (searchDevice !== "") {
            setIsLoading(true);
            getToken(instance).then(token => {
                const appService = new Devices();
                appService.getDevices(token.accessToken!, searchDevice).then((response: any) => {
                    const deviceData = Array.isArray(response.data) ? response.data : [];
                    setDevices(deviceData);
                    setIsLoading(false);
                });
            });
        }
    }, [instance, searchDevice]);

  return (
    <Flex className="devices-column" style={{ minHeight: minHeight }} flexDirection="column" bg="light-blue.600" p={3}>
        <Box className="devices-header" mb={3}>
            <Text as="h2" textStyle="2xl" color="white">Device List</Text>
        </Box>
        <Flex className="devices-container" direction="column">
            <Box className="device-search" mb={4}>
                <Search
                    onChange={(value: string) => {
                        setSearchDevice(value);
                    }}

                    onEnterPressed={(value: string) => {
                        setSearchDevice(value);
                    }}

                    debounceMs={1000}
                    value={searchDevice}
                    size="md"
                    placeholder="Search for a device" />
            </Box>
            <Flex className="group-list-header" flexDirection="column" mb={3}>
                <Grid alignItems="center" templateColumns="repeat(2, 1fr)">
                    <Box className="group-list-header">
                        <Text as="h4" textStyle="lg" color="white">
                            {selectedDevice.length} {selectedDevice.length === 1 ? 'device' : 'devices'} selected.
                        </Text>
                    </Box>
                    <Box className="devices-reset-selection" textAlign="right">
                        <Button 
                            variant="outline" 
                            size="sm" 
                            color="white" 
                            borderColor="white" 
                            borderRadius="0" 
                            onClick={() => {
                                toast({
                                    position: 'bottom-right',
                                    duration: 5000,
                                    onCloseComplete,
                                    render: ({ onClose }) => (
                                    <InlineNotification variant="info">
                                        <InlineNotification.Content alignItems="flex-start" flexDirection="column">
                                            <InlineNotification.Title>Device Selection</InlineNotification.Title>
                                            <InlineNotification.Description>
                                                Device selection has been reset.
                                            </InlineNotification.Description>
                                        </InlineNotification.Content>
                                        <CloseButton aria-label="Close" onClick={onClose} />
                                    </InlineNotification>
                                    ),
                                });
                                resetDeviceSelection();
                            }} 
                            isDisabled={selectedDevice.length <= 0}
                            >
                            Reset
                        </Button>
                    </Box>
                </Grid>
            </Flex>
        </Flex>
        <Flex className="devices-container" direction="column" bg="white">
        {isLoading ? (
            <Box p={4}>
                <Spinner color="light-blue.600" mx="auto" />
                <Text as="h4" textStyle="xl" color="light-blue.600" fontWeight="bold" textAlign="center" my={2}>Loading device list...</Text>
            </Box>
        ) : (
            <Box className="device-list-container">
                {selectedDevice.length > 0 && (
                    <Box className="selected-device-list" p={4}>
                        <CheckboxGroup value={selectedDevice} onChange={handleSelectedDevices}>
                            {selectedDevice.map((deviceId) => {
                                const device = filteredDevices.find(d => d.id === deviceId);
                                return device ? (
                                    <Checkbox className="selected-devices" w="100%" key={device.id} value={device.id}>
                                        <DevicesLaptop /> {device.displayName}
                                    </Checkbox>
                                ) : null;
                            })}
                        </CheckboxGroup>
                    </Box>
                )}
                {selectedDevice.length > 0 && (
                    <Divider variant={'dashed'} />
                )}
                {filteredDevices.length > 0 ? (
                    <Box className="device-list" p={4}>
                        <CheckboxGroup value={selectedDevice} onChange={handleSelectedDevices}>
                            {filteredDevices
                                .filter(device => !selectedDevice.includes(device.id))
                                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                                .map((device) => (
                                    <Checkbox className="device" w="100%" key={device.id} value={device.id}>
                                        <DevicesLaptop /> {device.displayName}
                                    </Checkbox>
                            ))}
                        </CheckboxGroup>
                    </Box>
                ) : (
                    <Text bg="error.400" color="white" fontWeight="bold" textAlign="center" p={3}>No results found.</Text>
                )}
            </Box>
        )}
        </Flex>
    </Flex>
  );
};