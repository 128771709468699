import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getRoles, getToken } from "./auth/authConfig";
import { Grid } from "@lego/klik-ui";
import { Menu } from "./components/Menu";
import { DeviceList } from "./pages/DeviceList";
import { GroupList } from "./pages/GroupList";

function App() {
    const { instance } = useMsal();

    const [roles, setRoles] = useState<string[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<(string | number)[]>([]);

    const handleSelectedDevices = (newSelectedDevice: string[]) => {
      setSelectedDevice(newSelectedDevice);
      console.log('Selected devices:', newSelectedDevice);
    };

    const resetDeviceSelection = () => {
      setSelectedDevice([]);
    };

    useEffect(() => {
      getToken(instance).then(token => {
        setRoles(getRoles(token.accessToken));
      });
    }, [instance]);

    return (
      <>
        <Menu />
        <Grid className="main-content" templateColumns="1fr 3fr">
          <DeviceList selectedDevice={selectedDevice} handleSelectedDevices={handleSelectedDevices} resetDeviceSelection={resetDeviceSelection} />
          <GroupList selectedDevice={selectedDevice}  />
        </Grid>
      </>
    );
}

export default App;